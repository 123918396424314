<template>
  <div>

    <!-- frequently asked questions tabs pills -->

    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section

      class=" faq-contact"
    >
      <b-row class="mt-5 pt-75 justify-content-center ">
        <b-col
          v-if="!reseteado"
          cols="10"
        >
          <h2>Resetea tu password</h2>
          <p>Ingresa tu nuevo pasword </p>

          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="icons-search"
                v-model="newPassword"
                placeholder="Nuevo Password..."
              />
            </b-input-group>
            <b-button
              class="mt-2"
              @click="enviaNewPass()"
            >
              Enviar
            </b-button>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          cols="10"
        >
          <h2>Ya puedes cerrar esta ventana</h2>
          <p>PASSWORD RESETEADO </p>
          <p>Por favor intenta ingresar con tu email y tu nuevo password</p>
        </b-col>

      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BRow, BCol, BInputGroup, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

import axios from 'axios'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      reseteado: false,
      faqSearchQuery: '',
      faqData: {},
      newPassword: null,
      token: null,
      tokenId: null,
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        // this.fetchData()
      },
    },
  },
  mounted() {
    // console.log(this.$route.query.tokenId) // outputs 'yay'
    // console.log(this.$route.query.token) // outputs 'yay'
    this.tokenId = this.$route.query.tokenId
    this.token = this.$route.query.token
  },

  methods: {
    enviaNewPass() {
      const that = this
      axios.post('https://us-central1-walter-319318.cloudfunctions.net/rayoBot/checkRayoBot/hammerRealmPassReset', {
        new_password: this.newPassword,
        token: this.token,
        tokenId: this.tokenId,
      })
        .then(() => {
          // eslint-disable-next-line no-alert
          alert('El Password ha sido cambiado. Ya puedes cerrar esta web y usar tus nuevas credenciales')
          that.reseteado = true
        })
        .catch(error => {
          // eslint-disable-next-line no-alert
          alert(`Ha ocurrido un error. Por favor contacta al equipo de Dispatch de Rayo${error}`)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
